<template>
	<el-container :lang="$i18n.locale">
		<el-main>
			<div class="listHeader">
				<i></i><span>{{$t('clientInfoPage.pageTitle')}}</span>
			</div>
			<el-form :model="form" label-width="150px" ref="infoForm" :inline="true">
				<el-form-item :label="$t('clientInfoPage.clientFullName')" prop="clientFullName">
					<el-input
					  v-model="form.clientFullName"
					  style="width: 350px;" disabled
					></el-input>
				</el-form-item>
				<el-form-item :label="$t('clientInfoPage.clientShortName')" prop="clientShortName">
					<el-input
					  v-model="form.clientShortName"
					  style="width: 350px;" disabled
					></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.contactMan')" prop="contacts">
					<el-input
					  v-model="form.contacts"
					  style="width: 350px;" disabled
					></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.contact')" prop="telephone">
					<el-input
					  v-model="form.telephone"
					  style="width: 350px;" disabled
					></el-input>
				</el-form-item>
				<el-form-item :label="$t('clientInfoPage.clientEmail')" prop="email">
					<el-input
					  v-model="form.email"
					  style="width: 350px;" disabled
					></el-input>
				</el-form-item>
				<!-- <el-form-item label="Fax" prop="fax">
					<el-input
					  v-model="form.fax"
					  style="width: 350px;" disabled
					></el-input>
				</el-form-item> -->
				<el-form-item :label="$t('clientInfoPage.clientPrefixNo')" prop="prefixAwbNo">
					<el-input
					  v-model="form.prefixAwbNo"
					  style="width: 350px;" disabled
					></el-input>
				</el-form-item>
				<!-- <el-form-item label="InitTime" prop="initTime">
					<el-input
					  v-model="form.initTime"
					  style="width: 350px;" disabled
					></el-input>
				</el-form-item> -->
				<!-- <el-form-item label="Address" prop="address">
					<el-input
					  type="textarea"
					  v-model="form.address"
					  style="width: 860px;" disabled
					></el-input>
				</el-form-item> -->
			</el-form>
			
			<el-divider></el-divider>
			
			<!-- 绑定二维码信息 -->
			<div class="listHeader">
				<i></i><span>{{$t('clientInfoPage.wcServiceAccount')}}</span>
			</div>
			<el-steps :active="1" simple>
				<el-step status="finish" title="扫描关注服务号" icon="el-icon-full-screen"></el-step>
				<el-step status="success" title="进行账号绑定" icon="el-icon-edit"></el-step>
				<el-step status="process" title="实时推送订单轨迹" icon="el-icon-s-promotion"></el-step>
			</el-steps>
			<div class="bindFleetan">
				<div class="imgBox">
					<span>Fleetan 服务号二维码</span>
					<el-image @click="handleCheckImg(fleetanServiceImg)" :src="fleetanServiceImg"></el-image>
				</div>
				<div class="bindTips">
					<h3>如何将Fleetan系统账号与微信进行关联?</h3>
					<p>1、扫描左侧Fleetan服务号二维码，并<strong>关注服务号</strong></p>
					<p>2、进入服务号，<strong>点击下方绑定账号按钮，扫描下方二维码</strong></p>
					<!-- <p>2、进入服务号，<strong>复制</strong>下方令牌信息<strong>发送到服务号</strong></p> -->
					<!-- <p>
						<el-button size="mini" type="text" icon="el-icon-copy-document" 
						@click="handleCopyInfo()">{{$t('clientInfoPage.clickAndCopy')}}</el-button>
						<span>{{'bind:' + form.prefixAwbNo + '##' + form.email}}</span>
					</p> -->
					<p>3、当服务号返回提示<strong>绑定成功</strong>即关联成功</p>
					<vue-qr v-show="clientQrCode !==''" :text="clientQrCode" :size="258"></vue-qr>
				</div>
				<div class="trackingTips">
					<span>Fleetan 服务号客户订单轨迹消息推送功能</span>
					<el-image @click="handleCheckImg(fleetanTrackingImg)" style="width:50%;" :src="fleetanTrackingImg"></el-image>
				</div>
			</div>
		</el-main>
		<div>
			<el-image-viewer style="z-index: 4000;" v-if="showImg" :on-close="closeImg" :url-list="imgList" />
		</div>
	</el-container>
</template>

<script>
	import vueQr from 'vue-qr'
	import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
	export default {
		data() {
			return {
				form:{},
				clientQrCode:'',
				fleetanServiceImg:require('@/assets/images/fleetanServiceAccount.jpg'),
				fleetanTrackingImg:require('@/assets/images/wx_tracking.jpg'),
				
				// 预览图片
				imgList: [],
				showImg: false,
			}
		},
		components: {
			vueQr,
			ElImageViewer
		},
		watch:{
			
		},
		created() {
			this.getCustomerInfo();
			this.getClientQrCode();
		},
		methods: {
			async getCustomerInfo(){
				let res = await this.$http.get("/toborder/customer/baseinfo/loginInfo");
				if(res.code == 200){
					this.form = res.data;
				}else{
					this.$message.error(res.msg)
				}
			},
			// 点击复制信息
			handleCopyInfo(){
				let data = 'bind:' + this.form.prefixAwbNo + '##' + this.form.email;
				this.copyData(data);
			},
			// 复制内容至剪切板
			copyData(data){
				this.$copyText(data).then( (e) => {
				  this.$message.success(this.$t('tipsInfo.copySuccess'))
				}, (e) => {
				  this.$message.error(this.$t('tipsInfo.copyFail'))
				})
			},
			// 获取当前用户绑定服务号密钥信息
			async getClientQrCode(){
				let res = await this.$http.get("/toborder/customer/baseinfo/getBindWechat");
				if(res.code == 200){
					this.clientQrCode = res.data;
				}else{
					this.$message.error(res.msg)
				}
			},
			// 查看图片
			handleCheckImg(url){
				this.imgList = [url]
				this.showImg = true;
			},
			//关闭图片
			closeImg() {
				this.showImg = false;
			},
		}
	}
</script>

<style scoped="scoped">
	.listHeader{
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
		padding: 10px 0px;
	}
	.listHeader i{
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}
	.listHeader span{
		display: inline-block;
		float: left;
	}

	.el-header{
		padding: 0 !important;
	}
	
	.bindFleetan{
		width: 100%;
	}
	.imgBox,.trackingTips{
		width: calc(100% / 3);
		text-align: center;
		padding: 20px 0;
		float: left;
	}
	.imgBox span,.trackingTips span{
		font-size: 20px;
		font-weight: bold;
		display: inline-block;
		width: 100%;
		height:40px;
		line-height:40px;
		color: #0072a3;
		position: relative;
		margin-bottom:30px;
	}
	.imgBox span::after{
		content: '';
		width: 100px;
		height: 3px;
		display: block;
		position: absolute;
		bottom: 0px;
		left: 50%;
		margin-left: -50px;
		background-color: #0072a3;
	}
	.bindTips{
		width: calc(100% / 3);
		float: left;
	}
	.bindTips h3{
	}
	.bindTips p{
		padding-left: 20px;
	}
	.bindTips p strong{
		color: #0077AA;
	}
	.bindTips p span{
		margin-left: 10px;
		color: #ab6811;
		font-weight: bold;
	}
	.bindTips img{
		margin: 0 auto;
		display: block !important; 
	}

	
	
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		::v-deep .el-form-item__label{
			float: right;
		}
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
		::v-deep .el-dropdown{
			margin: 0 5px 0 0 !important;
			.batchInfoItem{
				width: 20px;
				height: 20px;
				left: -15px;
				right: auto;
				top:-5px;
			}
			span{
				margin-left: -5px;
				float: right;
			}
			i{
				float: right;
				margin: 6px 0 0 5px;
			}
		}
		::v-deep .is-dot {
			left: -2px;
			right: auto;
			top: 6px;
		}
		::v-deep .el-dialog__headerbtn{
			right: auto;
			left: 20px;
		}
		::v-deep .el-dialog__footer {
			text-align: left;
			.el-button {
				margin: 0 10px;
			}
		}
		::v-deep .el-select .el-tag{
			margin: 0 5px;
		}
		::v-deep .el-select .el-tag__close.el-icon-close{
			right: 5px;
		}
		::v-deep .el-tabs__nav{
			float: right;
		}
		.dialog_orderNo {
			label{
				float: right;
				margin-right: 40px;
			}
		}
		::v-deep .el-timeline-item__node--normal{
			right: -15px;
		}
	}
</style>
